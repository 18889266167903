@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-header !important;
  }
  body {
    @apply font-header;
    @apply text-brand-navy3;
  }
  span,
  li {
    @apply font-header !important;
  }
  button,
  select,
  input,
  textarea {
    @apply focus:outline-none;
  }
}

@layer components {
  .avatar {
    @apply inline-block object-cover rounded-full;
  }
  .avatar-default {
    @apply inline-block align-middle rounded-full overflow-hidden bg-gray-100;
  }
  .avatar-xxl {
    @apply w-[7rem] h-[7rem];
  }
  .avatar-xl {
    @apply w-[5.125rem] h-[5.125rem];
  }
  .button {
    @apply px-4 h-12 rounded-lg font-bold;
  }
  .card {
    @apply flex flex-col rounded-lg border border-gray-200 overflow-hidden shadow;
  }

  .card-square {
    @apply block mb-[1.5rem] flex-col rounded-lg border border-[#edf2f9] overflow-hidden shadow-sm;
  }
  .checkbox {
    @apply wh-5 rounded-sm border-2 border-gray-200 text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1 cursor-pointer;
  }
  .dropdown {
    @apply relative w-max;
  }
  .dropdown-view {
    @apply absolute mt-4 w-max shadow-lg ring-1 ring-black ring-opacity-5 bg-white z-10
           focus:outline-none transition transform origin-top;
  }
  .label {
    @apply text-sm text-gray-800;
  }
  .label-col {
    @apply flex flex-col space-y-1;
  }
  .label-row {
    @apply flex items-center space-x-2;
  }
  .menu {
    @apply flex flex-col py-1;
  }
  .menu-item {
    @apply px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-800;
  }
  .pagination {
    @apply flex justify-between items-center px-6 py-3;
  }
  .pagination-label {
    @apply text-sm text-gray-700;
  }
  .pagination-nav {
    @apply flex shadow-sm -space-x-px;
  }
  .pagination-nav-button {
    @apply grid place-items-center p-2 min-w-10 border text-sm font-medium;
  }
  .radio {
    @apply wh-4 text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }
  .select {
    @apply px-4 h-12 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .sidebar {
    @apply flex flex-col w-64 border-r border-gray-200;
  }
  .sidebar-title {
    @apply flex items-center px-4 h-16 font-bold text-2xl text-gray-800;
  }
  .sidebar-menu {
    @apply flex-grow flex flex-col space-y-1 p-2 overflow-y-auto;
  }
  .sidebar-menu-item {
    @apply p-2 rounded-md text-sm font-medium flex items-center;
  }
  .table {
    @apply divide-y divide-gray-200;
  }
  .table-head {
    @apply bg-gray-50;
  }
  .table-body {
    @apply divide-y divide-gray-200;
  }
  .table-th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }
  .table-td {
    @apply px-6 py-4 max-w-xs text-left text-sm text-gray-500 truncate;
  }
  .textarea {
    @apply px-4 py-2 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 read-only:bg-gray-100 read-only:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .textfield {
    @apply px-4 h-12 border border-gray-200 rounded-md placeholder-gray-400 bg-white
           disabled:bg-gray-100 disabled:text-gray-400 read-only:bg-gray-100 read-only:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .toggle {
    @apply relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full
           cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1
           transition-colors ease-in-out duration-200;
  }
  .toggle-slider {
    @apply pointer-events-none inline-block w-5 h-5 rounded-full bg-white shadow ring-0
           transition ease-in-out duration-200 transform;
  }

  .froala-header {
    @apply !text-[18px] md:!text-[20px] lg:!text-[24px];
  }
}

@layer utilities {
  .max-w-container {
    @apply px-4 md:px-6 lg:px-6 xl:px-4 py-4 max-w-screen-xl mx-auto w-full;
  }
}

.react-datetime-picker__wrapper {
  @apply !rounded-md !border-gray-200 placeholder-gray-400 px-3 h-10;
}

.react-datetime-picker__inputGroup__input {
  @apply !bg-white !shadow-none !ring-offset-0 !ring-0;
}

@media (min-width: 600px) {
  :root {
    --rsbs-max-w: 600px;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
}
