.embedded-google-doc {
  display: block;
  width: 100%;
  max-width: 832px;
  /* height: 100vh; */
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
}

.embedded-google-doc > div {
  max-width: unset !important;
  padding: 20pt 20pt 20pt 20pt;
}

/* .c22 {
  
} */
